angular.module("app")
	.controller("forgotPasswordController", ["$log", "$state", "$location", "userService", function($log, $state, $location, userService){
		var ctrl = this;
		ctrl.running = false;
		ctrl.messages = [];
		ctrl.success = false;
		
		
		ctrl.submit = function(email){
			ctrl.running = true;
			ctrl.messages = [];
			userService.forgotPassword(email)
			 	.then(function(result){
			 			ctrl.success = true;
			 	})
			 	.catch(function(resp){
			 		if(resp.data.messages){
			 			ctrl.messages = resp.data.messages;
			 		} else {
			 			Notification.error({message: "Failed to reset your password, please try again", delay: 5000});
			 		}
			 	})
			 	.finally(function(){
			 		ctrl.running = false;
			 	});
		};
	}]);